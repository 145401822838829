import moment from 'moment'
export default {
    props: {
        lista: Array
    },
    data() {
        return {
            time_s: 0,
            filter_option: [
                {
                    text: 'Ultima Settimana',
                    value: {
                        ts_inizio: moment().subtract(1, "week").startOf("week").format("X"),
                        ts_fine: moment().format("X")
                    }
                },
                {
                    text: 'Ultimo Mese',
                    value: {
                        ts_inizio: moment().subtract(1, "month").startOf("month").format("X"),
                        ts_fine: moment().format("X")
                    }
                },
                {
                    text: 'Sei Mesi',
                    value: {
                        ts_inizio: moment().subtract(6, "months").startOf("month").format("X"),
                        ts_fine: moment().format("X")
                    }
                },
                {
                    text: moment().format("YYYY"),
                    value: {
                        ts_inizio: moment().startOf("year").format("X"),
                        ts_fine: moment().endOf("year").format("X")
                    }
                },
                {
                    text: moment().subtract(1, "year").format("YYYY"),
                    value: {
                        ts_inizio: moment().subtract(1, "year").startOf("year").format("X"),
                        ts_fine: moment().subtract(1, "year").endOf("year").format("X")
                    }
                },
                {
                    text: moment().subtract(2, "years").format("YYYY"),
                    value: {
                        ts_inizio: moment().subtract(2, "year").startOf("year").format("X"),
                        ts_fine: moment().subtract(2, "year").endOf("year").format("X")
                    }
                },
            ],
            loading: false
        }
    },
    computed: {
        filtered() {
            return this.lista
        }
    },
    methods: {
        fetch_ordini() {
            this.loading = true
            this.$store.dispatch("fetch_lista_ordini", this.time_s)
                .finally(() => {
                this.loading = false
            })
        }
    }
}