import printer from "@/service/printer";
export default {
  computed: {
    order_tot() {
      return (
        parseFloat(this.ordine.tot) + parseFloat(this.ordine.tot_trasp)
      ).toFixed(2);
    },
    status() {
      switch (this.ordine.stato) {
        case 0:
          return {
            icon: "mdi-clipboard-arrow-up",
            color: "grey",
            text: "In attesa di conferma",
          };
        case 1:
          return {
            icon: "mdi-clipboard-check",
            color: "green",
            text: "Confermato",
          };
        case 2:
          return {
            icon: "mdi-truck-fast",
            color: "blue",
            text: "Evaso",
          };
        case 3:
          return {
            icon: "mdi-close-circle",
            color: "error",
            text: "Rifiutato",
          };
        case 6:
          return {
            icon: "mdi-truck-fast",
            color: "blue",
            text: "In Consegna",
          };
        case 7:
          return {
            icon: "mdi-package-down",
            color: "purple",
            text: "Consegnato",
          };
        case 8:
          return {
            icon: "mdi-playlist-check",
            color: "purple",
            text: "Conteggiato",
          };
      }
    },
  },
  methods: {
    pdf() {
      printer.print_pdf(this.ordine._id.$oid);
    },
  },
};
