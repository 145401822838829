//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ordine_detatglio_element_mixin from "../mixin/ordine_dettaglio_element_mixin";

export default {
  props: {
    item: Object,
  },
  mixins: [ordine_detatglio_element_mixin],

  computed: {
    nome() {
      if (this.item.nome.includes("Vaso")) {
        return this.item.nome.split("Vaso")[0];
      } else {
        return this.item.nome.split("Basket")[0];
      }
    },
  },

  // data() {
  //   return {
  //     show_color: false,
  //   };
  // },
  // computed: {
  //   avatar_url() {
  //     return process.env.VUE_APP_API_ROOT + "/avatar_img/" + this.art.art;
  //   },
  //   nome() {
  //     if (this.art.nome.includes("Vaso")) {
  //       return this.art.nome.split("Vaso")[0];
  //     } else {
  //       return this.art.nome.split("Basket")[0];
  //     }
  //   },
  //   tot_pezzi() {
  //     return this.art.colori
  //       .filter((colore) => !colore.eliminato)
  //       .map((col) => parseInt(col.numero + col.qta_admin))
  //       .reduce((tot, num) => tot + num);
  //   },
  //   prezzo_tot() {
  //     let tot = this.tot_pezzi * this.art.prezzo;
  //     return tot.toFixed(2);
  //   },
  // },
  // methods: {
  //   colore_url(id) {
  //     return (
  //       process.env.VUE_APP_API_ROOT +
  //       "/prodotto/" +
  //       this.art.art +
  //       "/img_colore/" +
  //       id
  //     );
  //   },
  // },
};
