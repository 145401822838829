//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ordine_dett_mixin from "../mixin/ordine_dettaglio_mixin";
import DettaglioElement from "./ordine_dettaglio_element_mobile";
export default {
  components: {
    DettaglioElement,
  },
  props: {
    ordine: Object,
  },
  mixins: [ordine_dett_mixin],
  data() {
    return {
      dialog: false,
    };
  },
  watch: {
    $route(to, from) {
      if (from.hash === "#dettaglio-ordine" && this.dialog) {
        this.dialog = false;
      }
    },
  },
  methods: {
    open() {
      this.dialog = true;
      this.$router.push("#dettaglio-ordine");
    },
    close() {
      this.dialog = false;
      this.$router.back();
    },
  },
};
