//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EliminaOrdine from "../elimina_ordine_dialog";
import OrdineDettaglio from "./ordine_dettaglio_mobile"
import ordine_el_mixin from "../mixin/ordine_element_mixin";
export default {
  components: {
    EliminaOrdine,
    OrdineDettaglio,
  },
  mixins: [ordine_el_mixin],
  methods:{
    apri_dett(){
      this.$refs.dett_ordine.open()
    }
  }
};
