//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    ordine: Object,
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    elimina() {
      this.$store
        .dispatch("elimina_ordine", this.ordine._id.$oid)
        .then(() => {
          this.dialog = false;
        })
        .catch((err) => {
          if (err.status == 400) {
            this.$router.push("/login");
          }
        });
    },
  },
};
